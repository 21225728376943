<template>
  <div>
    <div class="flights-bg-ats">
      <v-container class="cont">
        <v-row align="center" justify="center" class="inner-cont" no-gutters>
          <!-- <v-col cols="12"> -->
          <v-card class="pa-5 rounded-xl">
            <flightSearch />
          </v-card>
          <!-- </v-col> -->
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import flightSearch from '../components/flightSearch.vue'
export default {
  title: '-Flight search',
  components: {
    flightSearch
  }
}
</script>

<style>
.cont,
.inner-cont {
  min-height: calc(100vh - 65px);
}
.bg {
  height: 100%;
  min-height: calc(100vh - 65px);
  background: url("https://dev.atnbookingengine.com/images/assets/flight.jpeg");
  background-size: cover;
  background-position: bottom center;
}
.flights-bg-ats {
  height: 100%;
  min-height: calc(100vh - 54px);
  background: url("https://dev.atnbookingengine.com/images/assets/flight.jpeg");
  background-size: cover;
  background-position: bottom center;
}
@media screen and (max-width: 959px) {
  .cont {
    height: fit-content !important;
  }
  .inner-cont {
    height: auto;
    margin: 64px auto !important;
  }
}
</style>
